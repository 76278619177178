<template>
  <div class="s-flex-direction--column">
    <headerSimple :title="title()" />
    <router-view/>
  </div>
</template>
<script>
import headerSimple from '@/components/headerSimple.vue'

export default {
  components: {
    headerSimple
  },
  mounted () {
    window.scrollTo(0, 0)
  },
  methods: {
    title () {
      switch (this.$route.name) {
        case 'form2':
          return 'Book'
      }
      return null
    }
  }
}
</script>

<style lang="less">
  header.s-header {
    position: fixed;
    max-width: 1600px;
  }
  .s-headerTitle {
    top: -4px;
    position: relative;
    margin-left: 50px;
    padding-left: 20px !important;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 2px;
      height: 24px;
      background: #fff;
      left: 0;
      top: -3px;
      border-radius: 2px;
      overflow: hidden;
    }
  }
  .quoteSection,
  .quoteBlock {
    display: none !important;
  }
</style>
